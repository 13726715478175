import { safelyMoveLayer, safelyRemoveLayer, safelyRemoveSource } from "./maps.utils";

let layerId = 0;

export abstract class MapLayer {

  public id: string;
  protected map: mapboxgl.Map;

  abstract addTo(map: mapboxgl.Map): Promise<void>;
  abstract layerIds(): string[];

  constructor() {
    this.id = `coreo-${layerId++}`;
  }

  async init(): Promise<void> { }

  show(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'visible');
      }
    }
  }

  hide(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'none');
      }
    }
  }

  move(): void {
    for (const l of this.layerIds()) {
      safelyMoveLayer(this.map, l);
    }
  }

  remove() {
    for (const l of this.layerIds()) {
      safelyRemoveLayer(this.map, l);
    }
    safelyRemoveSource(this.map, this.id);
    this.map = undefined;
  }

  hasLayer(id: string) {
    return this.layerIds().includes(id);
  }
}
